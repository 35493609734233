import React from "react";
import Chrome from "./chrome-store.png";

export default function CTA() {
  return (
    <div className="cta">
      <img
        className="landIMG_chrome"
        src={Chrome}
        alt="an example of the Next Call Product"
      />
      <a href="https://chrome.google.com/webstore/detail/nextcallxyz/hiadipcolhbbnielnehacfollbpemloc">Add to chrome</a>
    </div>
  );
}
