import React, { Component } from "react";
import ProductImage from "./Example-image.png";
import GoogleCalendar from "./Google-calendar.png";
import OutlookCalendar from "./outlook-calendar.png";
import Login from "./login.png";
import Refresh from "./refresh-gif-2.gif";
import JoinImage from "./click-join.png";
import Meets from "./google-meets.png";
import skype from "./skype.jpg";
import teams from "./microsoft-teams.jpg";
import zoom from "./zoom.jpg";

import CTA from "./CTA.jsx";

export default class Home extends Component {
  render() {
    return (
      <div className="container">
        <div className="card card_landing">
          <div className="card_text">
            <h1 style={{ textAlign: "center" }}>
              A chrome extension to join upcoming calls in one click.
            </h1>
            <p
              className="subHead"
              style={{ alignSelf: "center", marginTop: "20px" }}
            >
              Our extension automatically opens popular conferencing solutions
              like Zoom, Google Meet, Skype, Microsoft Teams and many more.
            </p>

            <img
              className="landIMG"
              src={ProductImage}
              alt="an example of the Next Call Product"
            />
            <p className="support">Supported video conferencing</p>
            <div className="calendars calendars_video">
              <img
                className="calendars_img"
                src={teams}
                alt="google calendar"
              />
              <img
                className="calendars_img calendars_img-2"
                src={skype}
                alt="outlook calendar"
              />
              <img
                className="calendars_img calendars_img-2"
                src={Meets}
                alt="outlook calendar"
              />
              <img
                className="calendars_img calendars_img-2"
                src={zoom}
                alt="outlook calendar"
              />
            </div>
            <p className="support_info">And more coming soon...</p>
            <p className="support">Supported calendars</p>
            <div className="calendars">
              <img
                className="calendars_img"
                src={GoogleCalendar}
                alt="google calendar"
              />
              <img
                className="calendars_img calendars_img-2"
                src={OutlookCalendar}
                alt="outlook calendar"
              />
            </div>
            <p className="support_info">And more coming soon...</p>

            <CTA />

            <h2
              style={{
                textAlign: "center",
                marginTop: "20px",
                alignSelf: "center",
              }}
            >
              Here's how it works:
            </h2>
            <h3 className="step">1: Log into your calendar</h3>
            <p>
              You log in to your Google Calendar or Outlook calendar from our
              chrome extension.
            </p>

            <img
              className="landIMG"
              src={Login}
              alt="an example of logging into Next Call Product"
            />
            <h3 className="step">
              <span role="img" aria-label="down">
                👇
              </span>
              Hint:
            </h3>
            <p>
              Add as many calendars as you want! Click configure calendars to
              add additional calendars.
            </p>

            <h3 className="step">2: Refresh Events</h3>
            <p>Refresh Events to pull any latest updates from your calendar.</p>
            <img
              className="landIMG"
              src={Refresh}
              alt="an example of logging into Next Call Product"
            />
            <h3 className="step">3: Click join</h3>

            <p>
              Click join to open your next Zoom or Google meet in a new tab.
            </p>
            <img
              className="landIMG"
              src={JoinImage}
              alt="an example of clicking join"
            />
            <CTA />
            <div className="footer">
              <h1>NextCall</h1>
              <div className="footer_links">
                <a href="/termsofservice">Terms of Service</a>
                <a href="/privacystatement">Privacy Policy</a>
              </div>
            </div>
          </div>
        </div>
        <p className="creds">
          Made by{" "}
          <a href="https://twitter.com/karthikkalyan90" target="blank">
            {" "}
            Karthik
          </a>{" "}
          and{" "}
          <a href="https://twitter.com/scheuercharles" target="blank">
            {" "}
            Charles
          </a>{" "}
        </p>
        <p>
          Inspired by <a href="https://twitter.com/pr0duktiv">Patrice</a> and
          Lenni's{" "}
          <a href="https://trymeeter.com" target="blank">
            Meeter
          </a>
        </p>
      </div>
    );
  }
}
