import React, { Component } from "react";

var api="https://us-central1-calendar-276823.cloudfunctions.net/nextcallfyi/microsoft/connect/";

export default class RedirectMicrosoft extends Component {

  redirectToBackend = () => {
    const params = new URLSearchParams(this.props.location.search);
    const uuid = params.get('uuid');
    const cal_id = params.get('cal_id');
    api += `${uuid}/${cal_id}`;
    console.log(api);
    window.open(api);
  }

  componentDidMount() {
    this.redirectToBackend();
  }

  render() {
    return(<div>
      Redirecting...
    </div>)
  }
}