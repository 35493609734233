import React, { Component } from "react";

import demo from "./demo.gif";

export default class Home extends Component {
  render() {
    return (
      <div className="container">
        <div className="card">
          <div className="card_text">
            <h1>Welcome to Next Call</h1>
            <h2>Thanks for connecting your calendar.</h2>
            <p>
              You should now see your next events and be able to click join for
              any events that provide a meeting link.
            </p>
            <div className="hint">
              <span role="img" aria-label="down" className="emoji">
                👇
              </span>
              <p className="hint_text">Helpful hint</p>
            </div>
            <div className="tip">
              <p className="tip_desc">
                You can always add more calendars from our extension by clicking
                'configure calendars'
              </p>
            </div>
            <div className="gif">
              <img src={demo} alt="demo" />
            </div>
          </div>

          <div className="help">
            <a href="mailto:nextcall.xyz@gmail.com" className="help_text">
              Need help?
            </a>
          </div>
        </div>
        <p className="creds">
          Made by{" "}
          <a href="https://twitter.com/karthikkalyan90" target="blank">
            {" "}
            Karthik
          </a>{" "}
          and{" "}
          <a href="https://twitter.com/scheuercharles" target="blank">
            {" "}
            Charles
          </a>{" "}
        </p>
        <p>
          Inspired by <a href="https://twitter.com/pr0duktiv">Patrice</a> and
          Lenni's{" "}
          <a href="https://trymeeter.com" target="blank">
            Meeter
          </a>
        </p>
      </div>
    );
  }
}
