import React, { Component } from "react";

var api="https://us-central1-calendar-276823.cloudfunctions.net/nextcallfyi/google/code";

export default class RedirectGoogle extends Component {

  redirectToBackend = () => {
    const params = new URLSearchParams(this.props.location.search);
    const code = params.get('code');
    const state = params.get('state');
    api += `?code=${code}&state=${state}`;
    window.open(api, "_self");
    // fetch(api, {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   })
    //     .then(function (response) {
    //         console.log("done");
    //     //   return response.json();
    //     })
  }

  componentDidMount() {
    this.redirectToBackend();
  }

  render() {
    return(<div>
      Redirecting...
    </div>)
  }
}