import React, { Component } from "react";

export default class Home extends Component {
  render() {
    return (
      <div className="container">
        <div className="card">
          <div className="card_text">
            <h1>NextCall Privacy Policy</h1>
            <p>
              Your privacy is important to us. It is NextCall.xyz's policy to
              respect your privacy regarding any information we may collect from
              you across our website, https://nextcall.xyz, and other sites we
              own and operate. We only ask for personal information when we
              truly need it to provide a service to you. We collect it by fair
              and lawful means, with your knowledge and consent. We also let you
              know why we’re collecting it and how it will be used. We only
              retain collected information for as long as necessary to provide
              you with your requested service. What data we store, we’ll protect
              within commercially acceptable means to prevent loss and theft, as
              well as unauthorized access, disclosure, copying, use or
              modification. We don’t share any personally identifying
              information publicly or with third-parties, except when required
              to by law. Our website and software may link to external sites
              operated by Google and Microsoft that are not operated by us. Please
              be aware that we have no control over the content and practices of
              these sites, and cannot accept responsibility or liability for
              their respective privacy policies. You are free to refuse our
              request for your calendar access, with the understanding that we
              may be unable to provide you with some of your desired services.
              Your continued use of our website will be regarded as acceptance
              of our practices around privacy and personal information. If you
              have any questions about how we handle user data and personal
              information, feel free to contact us. This policy is effective as
              of 14 June 2020.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
