import React, { Component } from "react";
import Home from "./Home";
import Terms from "./Terms";
import Landing from "./Landing";
import Privacy from "./Privacy";
import RedirectGoogle from "./RedirectGoogle";
import RedirectMicrosoft from "./RedirectMicrosoft";
import "./App.scss";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: "someuser",
      cal_id: "gcal-extra",
      addNew: false,
    };
  }

  addNew = () => {
    this.setState({ addNew: true });
  };

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <Landing />
          </Route>
          <Route exact path="/success">
            <Home addNew={this.addNew} />
          </Route>
          <Route exact path="/termsofservice">
            <Terms />
          </Route>
          <Route exact path="/privacystatement">
            <Privacy />
          </Route>
          <Route path="/redirect" component={RedirectGoogle} />
          <Route path="/microsoft/connect" component={RedirectMicrosoft} />
        </Switch>
      </Router>
    );
  }
}

export default App;
